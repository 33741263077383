<template>
  <div class="builds-container mt-6">
    <CyNotification
      v-if="_.get(pipeline, 'paused')"
      v-has-rights-to="['UnpausePipeline', pipelineCanonical]"
      class="my-4"
      :title="$t('pipelinePaused.title')"
      :content="$t('pipelinePaused.text')"
      :button-label="$t('resumeBtn')"
      @click="unpausePipeline"/>

    <div
      :class="['job', {
        'paused': jobFromAPI.paused,
        [_.$get(buildFromAPI, 'status', 'nobuildstatus')]: !jobFromAPI.paused,
        'mt-4 pt-7': isHeaderSticky,
      }]">
      <v-row
        align="center"
        no-gutters>
        <v-col
          class="job__info d-flex"
          cols="2">
          <CyTooltip
            v-has-rights-to="['UpdatePipeline', pipelineCanonical]"
            top>
            <template #activator="{ on }">
              <v-btn
                slot="activator"
                :loading="loadingPause"
                text
                large
                block
                class="job__pause-button"
                @click="handlePause"
                v-on="on">
                <v-icon v-if="jobFromAPI.paused">
                  play_arrow
                </v-icon>
                <v-icon v-else>
                  pause
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('pauseUnpauseJob') }}
            </span>
          </CyTooltip>
          <v-divider
            vertical
            class="divider"/>
          <h6
            v-if="buildFromAPI.job_name"
            class="h4 pl-4">
            {{ buildFromAPI.job_name }} #{{ buildFromAPI.name }}
          </h6>
          <h6
            v-else
            class="h4 pl-4">
            {{ jobCanonical }}
          </h6>
        </v-col>

        <v-col cols="3">
          <template v-if="dateNow">
            <CyTooltip
              v-if="buildFromAPI.start_time"
              top>
              <template #activator="{ on }">
                <v-row
                  slot="activator"
                  no-gutters
                  v-on="on">
                  <v-col
                    cols="4"
                    class="text-right pr-2">
                    {{ $t('jobStarted') }}
                  </v-col>
                  <v-col cols="8">
                    {{ startedFromNow }}
                  </v-col>
                </v-row>
              </template>
              {{ startedAtFullDate }}
            </CyTooltip>
            <CyTooltip
              v-if="buildFromAPI.end_time"
              top>
              <template #activator="{ on }">
                <v-row
                  slot="activator"
                  no-gutters
                  v-on="on">
                  <v-col
                    cols="4"
                    class="text-right pr-2">
                    <span>{{ $t('jobFinished') }}</span>
                  </v-col>
                  <v-col cols="8">
                    {{ finishedFromNow }}
                  </v-col>
                </v-row>
              </template>
              {{ finishedAtFullDate }}
            </CyTooltip>
            <v-row
              v-if="buildFromAPI.start_time && buildFromAPI.end_time"
              no-gutters>
              <v-col
                cols="4"
                class="text-right pr-2">
                <span>{{ $t('jobDuration') }}</span>
              </v-col>
              <v-col cols="8">
                {{ jobDuration }}
              </v-col>
            </v-row>
          </template>
        </v-col>

        <v-col
          offset="5"
          cols="2"
          class="text-right pr-4 space-x-3">
          <CyTooltip
            v-if="buildFromAPI.status === 'pending' || buildFromAPI.status === 'started'"
            v-has-rights-to="['AbortBuild', jobCanonical]"
            left>
            <template #activator="{ on }">
              <span v-on="on">
                <CyButton
                  :loading="loadingAbortBuild"
                  :disabled="loadingAbortBuild"
                  theme="primary"
                  variant="tertiary"
                  icon="clear"
                  icon-only
                  class="white-btn"
                  @click="abortBuild"/>
              </span>
            </template>
            {{ $t('abortBuild') }}
          </CyTooltip>

          <CyTooltip
            v-has-rights-to="['RerunBuild', pipelineCanonical]"
            left>
            <template #activator="{ on }">
              <span v-on="on">
                <CyButton
                  :loading="loadingRetriggerBuild"
                  :disabled="loadingRetriggerBuild || loadingPipeline || _.get(pipeline, 'paused')"
                  theme="primary"
                  variant="tertiary"
                  icon="repeat_one"
                  icon-only
                  class="white-btn"
                  @click="triggerExistingBuild"/>
              </span>
            </template>

            <span>
              {{ $t(_.get(pipeline, 'paused') ? 'pausedBtnTooltipText' : 'triggerExistingBuild') }}
            </span>
          </CyTooltip>

          <CyTooltip
            v-has-rights-to="['CreateBuild', pipelineCanonical]"
            left>
            <template #activator="{ on }">
              <span v-on="on">
                <CyButton
                  :loading="loadingNewBuild"
                  :disabled="loadingNewBuild || loadingPipeline || _.get(pipeline, 'paused')"
                  theme="primary"
                  variant="tertiary"
                  icon="add"
                  icon-only
                  class="white-btn"
                  @click="triggerNewBuild"/>
              </span>
            </template>

            <span>
              {{ $t(_.get(pipeline, 'paused') ? 'pausedBtnTooltipText' : 'triggerNewBuild') }}
            </span>
          </CyTooltip>
        </v-col>
      </v-row>
    </div>

    <ul
      id="builds-list"
      v-has-rights-to="'GetBuilds'">
      <li
        v-for="build in buildsFromAPI"
        :key="build.id"
        v-has-rights-to="['GetBuild', jobCanonical]"
        :class="[
          'build-number',
          build.id === buildId ? `current ${buildFromAPI.status}` : `${build.status}-faded`,
        ]">
        <router-link
          v-if="!build.reap_time"
          :to="buildLink(build.id)">
          {{ build.name }}
        </router-link>
      </li>
    </ul>

    <div
      v-if="buildFromAPI.reap_time"
      class="d-flex flex-column align-center fill-height pt-12 text-center">
      <CyBuildReaped class="mr-12 mb-4"/>
      <h1
        v-if="buildFromAPI.job_name">
        {{ buildFromAPI.job_name }} #{{ buildFromAPI.name }}
      </h1>
      <h4 class="pt-4 pb-4">
        {{ startDate }} - {{ reapedDate }}
      </h4>
      <span>{{ $t('reapedNoLongerAvailable') }}</span>
      <p class="reaped-explanation">
        {{ $t('reapedExplanation') }}
      </p>
    </div>

    <CyViewsBuildLeaf
      v-else-if="_.get(planFromAPI, 'plan') && resourcesFromAPI && buildFromAPI.status !== 'pending'"
      v-has-rights-to="['GetBuildResources', jobCanonical]"
      :resources="resourcesFromAPI"
      :item="planFromAPI.plan"
      :content="concourseContent"/>

    <v-card v-else-if="buildFromAPI.status === 'pending'">
      <v-card-title>
        <div class="h6 d-flex align-center mb-2">
          <v-icon class="mr-2">
            settings
          </v-icon> {{ $t('preparingBuild') }}
        </div>
        <v-progress-linear
          indeterminate
          height="8"
          color="secondary"/>
      </v-card-title>
      <v-card-text class="content">
        <ul
          v-if="buildPreparationFromAPI"
          class="prep-status-list">
          <li class="prep-status">
            <v-icon
              v-if="buildPreparationFromAPI.paused_pipeline === 'not_blocking'"
              color="white">
              done
            </v-icon>
            <v-progress-circular
              v-if="buildPreparationFromAPI.paused_pipeline === 'blocking'"
              indeterminate
              size="24"
              color="secondary"/>
            <span>{{ $t('checkingPipelineNotPaused') }}</span>
            <ul class="details"/>
          </li>
          <li class="prep-status">
            <v-icon
              v-if="buildPreparationFromAPI.paused_job === 'not_blocking'"
              color="white">
              done
            </v-icon>
            <v-progress-circular
              v-if="buildPreparationFromAPI.paused_job === 'blocking'"
              indeterminate
              size="24"
              color="secondary"/>
            <span>{{ $t('checkingJobNotPaused') }}</span>
            <ul class="details"/>
          </li>
          <li class="prep-status">
            <v-icon
              v-if="buildPreparationFromAPI.inputs_satisfied === 'not_blocking'"
              color="white">
              done
            </v-icon>
            <v-progress-circular
              v-if="buildPreparationFromAPI.inputs_satisfied === 'blocking'"
              indeterminate
              size="24"
              color="secondary"/>
            <span>{{ $t('waitingInputVersions') }}</span>
            <ul class="details">
              <li
                v-for="(value, key) in buildPreparationFromAPI.missing_input_reasons"
                :key="key">
                <span>{{ key }}</span> - <span>{{ value }}</span>
              </li>
            </ul>
          </li>
          <li class="prep-status">
            <v-icon
              v-if="buildPreparationFromAPI.max_running_builds === 'not_blocking'"
              color="white">
              done
            </v-icon>
            <v-progress-circular
              v-if="buildPreparationFromAPI.max_running_builds === 'blocking'"
              indeterminate
              size="24"
              color="secondary"/>
            <span>{{ $t('checkingMaxRunningJobs') }}</span>
            <ul class="details"/>
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { constructBreadcrumb } from '@/utils/helpers'
import { mapActions, mapMutations, mapState } from 'vuex'
import CyViewsBuildLeaf from '@/components/views/build-leaf'
import CyBuildReaped from '@/components/build-reaped'
import concourseMixin from '@/mixins/concourse'
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment
import 'moment-duration-format'
export const REFRESH_INTERVAL_TIMER = 1000
export const REFRESH_INTERVAL_BUILD = 10000

export default {
  name: 'CyPageBuilds',
  components: {
    CyBuildReaped,
    CyViewsBuildLeaf,
  },
  mixins: [concourseMixin],
  breadcrumb () {
    const { projectCanonical, envCanonical, pipelineCanonical, jobCanonical, projectName } = this
    return constructBreadcrumb(this.$options.name, jobCanonical, [
      {
        label: this.$t('routes.jobs'),
      },
      {
        label: this.$t('routes.pipeline'),
        name: 'pipeline',
        params: { projectCanonical, envCanonical, pipelineCanonical },
      },
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    return { title: this.jobCanonical }
  },
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
    envCanonical: {
      type: String,
      default: '',
    },
    pipelineCanonical: {
      type: String,
      default: '',
    },
    jobCanonical: {
      type: String,
      default: '',
    },
    buildId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    jobFromAPI: {},
    buildFromAPI: {},
    buildsFromAPI: [],
    buildPreparationFromAPI: {},
    planFromAPI: {},
    resourcesFromAPI: {},
    getBuildInformation: true,
    dateNow: null,
    isHeaderSticky: false,
    loadingNewBuild: false,
    loadingRetriggerBuild: false,
    loadingAbortBuild: false,
    loadingPause: false,
    loadingPipeline: false,
  }),
  computed: {
    ...mapState('organization/project', {
      pipelines: (state) => state.pipelines,
    }),
    ...mapState('organization/project/pipeline', {
      pipeline: (state) => state.detail,
      pipelineErrors: (state) => state.errors.pipeline,
    }),
    startedAtFullDate () {
      return moment.unix(this.buildFromAPI.start_time).format('LLL') // eslint-disable-line you-dont-need-momentjs/format
    },
    startedFromNow () {
      // eslint-disable-next-line you-dont-need-momentjs/diff, you-dont-need-momentjs/format
      return moment.duration(moment(this.dateNow).diff(this.buildFromAPI.start_time), 'seconds').format('d[d] h[h] m[m] s[s]')
    },
    finishedAtFullDate () {
      return moment.unix(this.buildFromAPI.end_time).format('LLL') // eslint-disable-line you-dont-need-momentjs/format
    },
    finishedFromNow () {
      // eslint-disable-next-line you-dont-need-momentjs/diff, you-dont-need-momentjs/format
      return moment.duration(moment(this.dateNow).diff(this.buildFromAPI.end_time), 'seconds').format('d[d] h[h] m[m] s[s]')
    },
    jobDuration () {
      // eslint-disable-next-line you-dont-need-momentjs/diff, you-dont-need-momentjs/format
      return moment.duration(moment(this.buildFromAPI.end_time).diff(this.buildFromAPI.start_time), 'seconds').format('d[d] h[h] m[m] s[s]') // eslint-disable-line you-dont-need-momentjs/format
    },
    startDate () {
      return moment.unix(this.buildFromAPI.start_time).format('MM/DD/YYYY') // eslint-disable-line you-dont-need-momentjs/format
    },
    reapedDate () {
      return moment.unix(this.buildFromAPI.reap_time).format('MM/DD/YYYY') // eslint-disable-line you-dont-need-momentjs/format
    },
  },
  watch: {
    buildId () {
      this.init()
    },
    buildFromAPI () {
      this.handleBuildChanges()
    },
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.setStickyHeaderWidth)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.setStickyHeaderWidth)
    window.clearInterval(this.refreshIntervalID)
    window.clearInterval(this.refreshIntervalBuildID)
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions('organization/project/pipeline', [
      'GET_PIPELINE',
      'UNPAUSE_PIPELINE',
    ]),
    ...mapMutations('organization/project/pipeline', [
      'SET_PIPELINE',
    ]),
    ...mapMutations('organization/project', [
      'SET_PROJECT_PIPELINES',
    ]),
    init () {
      this.jobFromAPI = {}
      this.buildFromAPI = {}
      this.planFromAPI = {}
      this.resourcesFromAPI = {}
      this.getBuildInformation = true

      // Those attributes are coming from the mixin
      this.concourseContent = {}
      this.concourseLastEventId = -1

      this.getJobFromAPI()
      this.getBuildFromAPI()
      this.getBuildsFromAPI()
      this.getPipeline()

      window.clearInterval(this.refreshIntervalTimerID)
      this.refreshIntervalTimerID = window.setInterval(() => {
        this.dateNow = Date.now() / 1000
      }, REFRESH_INTERVAL_TIMER)
    },
    async getPipeline () {
      this.$toggle.loadingPipeline(true)
      await this.GET_PIPELINE(this.pipelineCanonical)
      this.$toggle.loadingPipeline(false)
    },
    handleBuildChanges () {
      window.clearInterval(this.refreshIntervalBuildID)
      if (this.buildFromAPI.status === 'pending') {
        this.refreshIntervalBuildID = window.setInterval(() => {
          this.getBuildPreparationFromAPI()
          this.getBuildFromAPI()
        }, REFRESH_INTERVAL_BUILD)
      }
    },
    handleScroll () {
      this.isHeaderSticky = window.scrollY > 190
      this.setStickyHeaderWidth()
    },
    async unpausePipeline () {
      const { pipelineCanonical } = this
      this.$toggle.loadingPipeline(true)
      await this.UNPAUSE_PIPELINE({ pipelineCanonical, showNotification: true })
      this.$toggle.loadingPipeline(false)
      if (_.isEmpty(this.pipelineErrors)) {
        this.SET_PIPELINE({ ...this.pipeline, paused: false })
        const pipelines = _.map(this.pipelines, (pipeline) => ({
          ...pipeline,
          paused: (pipeline?.canonical === this.pipelineCanonical) ? false : pipeline.paused,
        }))
        this.SET_PROJECT_PIPELINES(pipelines)
      }
    },
    setStickyHeaderWidth () {
      const stickyHeader = document.querySelector('.pipeline-btn-container')
      const stickyHeaderWidth = window.getComputedStyle(document.querySelector('.builds-container'))?.width

      if (stickyHeader) stickyHeader.style.width = `${parseInt(stickyHeaderWidth) + 5}px`
    },
    async handlePause () {
      this.loadingPause = true
      this.jobFromAPI.paused ? await this.unpauseJob() : await this.pauseJob()
      await this.getJobFromAPI()
      this.loadingPause = false
    },
    async unpauseJob () {
      await this.$cycloid.ydAPI.unpauseJob(this.orgCanonical, this.projectCanonical, this.pipelineCanonical, this.jobCanonical)
    },
    async pauseJob () {
      await this.$cycloid.ydAPI.pauseJob(this.orgCanonical, this.projectCanonical, this.pipelineCanonical, this.jobCanonical)
    },
    async triggerNewBuild () {
      this.loadingNewBuild = true
      const { data } = await this.$cycloid.ydAPI.createBuild(this.orgCanonical, this.projectCanonical, this.pipelineCanonical, this.jobCanonical) || {}
      this.loadingNewBuild = false
      if (data) {
        // If a build has been triggered, we move to the new one
        this.$router.push({ name: 'builds', params: { pipelineCanonical: this.pipelineCanonical, jobCanonical: this.jobCanonical, buildId: `${data.id}` } })
      }
    },
    async triggerExistingBuild () {
      this.loadingRetriggerBuild = true
      const { buildId, orgCanonical, jobCanonical, pipelineCanonical, projectCanonical } = this
      const { data } = await this.$cycloid.ydAPI.rerunBuild(orgCanonical, projectCanonical, pipelineCanonical, jobCanonical, buildId) || {}
      this.loadingRetriggerBuild = false
      if (data) {
        // If a build has been triggered, we move to the new one
        this.$router.push({ name: 'builds', params: { pipelineCanonical, jobCanonical, buildId: `${data.id}` } })
      }
    },
    async abortBuild () {
      this.loadingAbortBuild = true
      const resBody = await this.$cycloid.ydAPI.abortBuild(this.orgCanonical, this.projectCanonical, this.pipelineCanonical, this.jobCanonical, this.buildId)
      this.loadingAbortBuild = false
      if (resBody) {
        // If a has been aborted, we refresh the builds status to get the errored one
        this.getBuildsFromAPI()
      }
    },
    async getJobFromAPI () {
      const { data } = await this.$cycloid.ydAPI.getJob(this.orgCanonical, this.projectCanonical, this.pipelineCanonical, this.jobCanonical) || {}
      if (data) this.jobFromAPI = data
    },
    async getResourcesFromAPI () {
      const { data } = await this.$cycloid.ydAPI.getBuildResources(this.orgCanonical, this.projectCanonical, this.pipelineCanonical, this.jobCanonical, this.buildId) || {}
      if (data) this.resourcesFromAPI = data
    },
    async getPlanFromAPI () {
      const { data } = await this.$cycloid.ydAPI.getBuildPlan(this.orgCanonical, this.projectCanonical, this.pipelineCanonical, this.jobCanonical, this.buildId) || {}
      if (data) this.planFromAPI = data
    },
    async getBuildFromAPI () {
      if (_.$isEmpty(this.buildId)) return

      const { data } = await this.$cycloid.ydAPI.getBuild(this.orgCanonical, this.projectCanonical, this.pipelineCanonical, this.jobCanonical, this.buildId) || {}

      if (data) {
        this.buildFromAPI = data
        if (this.buildFromAPI.status !== 'pending' && this.getBuildInformation) {
          this.getBuildInformation = false
          this.getPlanFromAPI()
          this.getResourcesFromAPI()

          // This function is coming from the mixin
          this.setupStream()
        }
      }
    },
    async getBuildsFromAPI () {
      const { data } = await this.$cycloid.ydAPI.getBuilds(this.orgCanonical, this.projectCanonical, this.pipelineCanonical, this.jobCanonical) || {}
      if (data) this.buildsFromAPI = data
    },
    async getBuildPreparationFromAPI () {
      const { data } = await this.$cycloid.ydAPI.getBuildPreparation(this.orgCanonical, this.projectCanonical, this.pipelineCanonical, this.jobCanonical, this.buildId) || {}
      if (data) this.buildPreparationFromAPI = data
    },
    buildLink (buildId) {
      const { orgCanonical, projectCanonical, pipelineCanonical, jobCanonical, $route: { hash } } = this
      return {
        name: 'builds',
        params: {
          orgCanonical,
          projectCanonical,
          pipelineCanonical,
          jobCanonical,
          ...(buildId ? { buildId } : {}),
        },
        ...(hash ? { hash } : {}),
      }
    },
  },
  i18n: {
    messages: {
      en: {
        title: 'Builds',
        jobStarted: 'started',
        jobFinished: 'finished',
        jobDuration: 'duration',
        triggerNewBuild: 'Trigger a new build',
        triggerExistingBuild: 'Retrigger the selected build',
        abortBuild: 'Abort the current build',
        preparingBuild: 'Preparing build',
        checkingPipelineNotPaused: 'Checking pipeline is not paused',
        checkingJobNotPaused: 'Checking job is not paused',
        waitingInputVersions: 'Waiting for a suitable set of input versions',
        checkingMaxRunningJobs: 'Checking max-in-flight is not reached',
        btnBackToPipeline: 'Back to the pipeline',
        reapedNoLongerAvailable: 'This log is no longer available.',
        reapedExplanation: 'To prevent logs from flooding the database, the continuous integration will "exterminate" older builds in order to keep to the stored log limit.',
        pausedBtnTooltipText: 'Builds cannot be triggered while the pipeline is paused',
        pauseUnpauseJob: 'Pause/Unpause the job',
        pipelinePaused: {
          text: 'Pausing a pipeline will prevent builds from being triggered and stop the periodic checking for new versions of resources.',
          title: 'Pipeline paused',
        },
        resumeBtn: 'Resume',
      },
      es: {
        title: 'Builds',
        jobStarted: 'empezado',
        jobFinished: 'terminado',
        jobDuration: 'duración',
        triggerNewBuild: 'Disparar un nuevo build',
        triggerExistingBuild: 'Relanzar la build seleccionada',
        abortBuild: 'Abortar el build',
        preparingBuild: 'Preparando el build',
        checkingPipelineNotPaused: 'Verificando que la pipeline no esta en pausa',
        checkingJobNotPaused: 'Verificando que el job no esta en pausa',
        waitingInputVersions: 'Esperando los inputs del job',
        checkingMaxRunningJobs: 'Verificando que el número máximo de jobs no fue alcanzado',
        btnBackToPipeline: 'De vuelta a la pipeline',
        reapedNoLongerAvailable: 'Este log ya no está disponible',
        reapedExplanation: 'Para evitar que los logs sobrecarguen la base de datos, la integración continua "eliminará" los builds antiguos para mantener el limite de logs',
        pausedBtnTooltipText: 'Las compilaciones no se pueden activar mientras la pipeline está en pausa',
        pauseUnpauseJob: 'Pausar/Reanudar el job',
        pipelinePaused: {
          text: 'Pausar una pipeline evitará que se activen compilaciones y detendrá la comprobación periódica de nuevas versiones de recursos.',
          title: 'Pipeline pausada',
        },
        resumeBtn: 'Reanudar',
      },
      fr: {
        title: 'Builds',
        jobStarted: 'démarré',
        jobFinished: 'fini',
        jobDuration: 'durée',
        triggerNewBuild: 'Démarre un nouveau build',
        triggerExistingBuild: 'Relancer le build selectionné',
        abortBuild: 'Annule le build selectionné',
        preparingBuild: 'Préparation de la construction',
        checkingPipelineNotPaused: 'Vérification que la pipeline ne soit pas en pause',
        checkingJobNotPaused: 'Vérification que le job ne soit pas en pause',
        waitingInputVersions: 'En attente des inputs du job',
        checkingMaxRunningJobs: 'Vérification que le nombre maximum de job ne soit pas atteint',
        btnBackToPipeline: 'Revenir à la pipeline',
        reapedNoLongerAvailable: `Le log n'est plus disponible.`,
        reapedExplanation: `Pour éviter de saturer la base de données, le système d'intégration continue "extermine" les anciens builds afin de respecter un nombre limite de builds.`,
        pausedBtnTooltipText: 'Les builds ne peuvent pas être déclenchés lorsque le pipeline est en pause',
        pauseUnpauseJob: 'Pause/Reprendre le job',
        pipelinePaused: {
          text: `La suspension d'un pipeline empêchera les builds d'être déclenchées et arrêtera la vérification périodique des nouvelles versions des ressources.`,
          title: 'Pipeline interrompue',
        },
        resumeBtn: 'Recommencer',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .pipeline-btn-container {
    position: relative;
    top: 0;
    transition: top 0.3s ease-in-out;
    background: get-color("white");

    &--sticky {
      position: fixed;
      z-index: 10;
      top: 47px;
      width: 100% !important;
      margin-left: -54px;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 54px;
      border-bottom: 1px solid get-color("grey");
    }
  }

  .failed { background-color: get-color("build", "failed"); }
  .failed-faded { background-color: get-color("build", "failed-faded"); }
  .succeeded { background-color: get-color("build", "succeeded"); }
  .succeeded-faded { background-color: get-color("build", "succeeded-faded"); }
  .errored { background-color: get-color("build", "errored"); }
  .errored-faded { background-color: get-color("build", "errored-faded"); }
  .started { background-color: get-color("build", "running"); }
  .started-faded { background-color: get-color("build", "running-faded"); }
  .aborted { background-color: get-color("build", "aborted"); }
  .aborted-faded { background-color: get-color("build", "aborted-faded"); }
  .pending { background-color: get-color("build", "pending"); }
  .pending-faded { background-color: get-color("build", "pending-faded"); }
  .paused { background-color: get-color("build", "paused"); }
  .paused-faded { background-color: get-color("build", "paused-faded"); }

  #builds-list {
    display: block;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    list-style: none;
    white-space: nowrap;

    @extend %cy-scrollbars-always-show;

    li {
      display: inline-block;

      a {
        display: block;
        margin: 0;
        padding: 5px;
        color: get-color("grey", "light-2");
        font-size: 20px;
        font-weight: 700;
        line-height: 1em;
        text-align: center;
        text-decoration: none;
      }
    }

    .link-pipeline {
      position: sticky;
      z-index: 1;
      top: 0;
      left: 0;
      background-color: #424242;
      vertical-align: top;
    }
  }

  .nobuildstatus {
    background-color: #5d6d7e;
  }

  .job {
    display: flex;
    color: get-color("grey", "light-2");

    &__info {
      align-items: center;
      margin: 5px 0;
    }

    &__pause-button {
      min-width: 0 !important;
      color: get-color("grey", "light-2");

      i {
        font-size: 48px;
      }
    }
  }

  .content {
    padding-top: 16px;
    background-color: get-color("grey", "terminal");
    color: get-color("white") !important;
    font-family: $font-family-code;
    font-size: map.get($font-sizes, "sm");
  }

  .v-card {
    border: 1px solid lightgray;
  }

  .v-card__title {
    padding: 8px;
  }

  .prep-status-list {
    padding-left: 5px;
  }

  .prep-status {
    list-style-type: none;
    font-size: 14px;
    font-weight: bold;
  }

  .firefox-prevent-space {
    flex: 0 1 auto;
  }

  .reaped-explanation {
    max-width: 300px;
  }

  .divider {
    align-items: center;
    border-color: get-color("grey", "light-2");
  }

  .details {
    margin-left: 40px;
    font-weight: normal;
  }

  .cy-btn.cy-btn__variant--tertiary.v-btn.v-btn.white-btn {
    background: get-color("white");
  }
</style>
